.scene {

	align-items: center;
	flex-direction: column;
	height: 100vh;
	width: 100vw;
	position: relative;/*
	background: linear-gradient(-10deg, #ebe1ae10, #f0ecda20, #00000010), radial-gradient(circle at 10% 10%, #dff0da10, transparent);*/
	transition: filter 2s;
}

.scene .game-hand {

	position: absolute;
	bottom: 0;
	right: 0vw;
	/*overflow: hidden;*/
	width: 65vw;
	z-index: 2;
}

.scene .game-court {

	position: absolute;
	bottom: 0;
	left: 10vw;
	width: 65vw;
	z-index: 11;
}

.scene .opposite-area .game-hand {

	bottom: unset;
	right: unset;
	top: -2.5vw;
	left: 2vw;
	transform: rotateZ(180deg) translateY(5vw);
}

.game-card-box-wrapper {

	position: absolute;
	left: 0.3vw;
	top: calc(5vw + 10vh);
	z-index: 13;
	font-size: calc(0.5vw + 1vh);
}

.game-sub-card-box-wrapper {

	position: absolute;
	left: 0.3vw;
	top: calc(5vw + 10vh + 16em);
	z-index: 13;
	font-size: calc(0.5vw + 1vh);
}

.scene .game-buttons {

	position: absolute;
	font-size: 1vw;
	left: 4vw;
	bottom: 5vw;
	z-index: 3;
}

.game-area {

}

.scene .game-area-info {

	position: absolute;
	left: 9.5vw;
	bottom: 1vw;
	z-index: 3;
}

.scene .opposite-area .game-area-info {

	position: absolute;
	left: 61.5vw;
	right: unset;
	top: 1vw;
	padding-left: 2vw;
	height: 4vw;
}

.game-area-stat {

	margin: 1vw;
}

.game-area-stat .game-area-stat-icon {

	border-radius: 50%;
	width: 1.4vw;
	height: 1.4vw;
	margin-top: 0.3vw;
	margin-right: 0.6vw;
	filter: drop-shadow(0.2vw 0.2vw);
	object-fit: contain;
}

.game-area-stat .game-area-stat-num {

	color: #edeae4;
	font-weight: bold;
	width: 2.5vw;
	height: 2vw;
	font-size: 2vw;
	line-height: 2vw;
	text-shadow: 0.2vw 0.2vw #000000A0;
}

.game-area-stat .game-area-stat-num .sidenote {

	font-size: 1vw;
	margin-top: 0.3vw;
	color: #9d978d;
}

/*.player-1 .game-prestige-stat .game-area-stat-icon { filter: hue-rotate(203deg) brightness(1.3) contrast(1.2) drop-shadow(0.2vw 0.2vw); }*/

.scene .game-card-wrapper {

	filter: drop-shadow(0.15rem 0.15rem);
}

.scene .game-card-wrapper .sensuba-card {

	filter: none;
}


.scene .opposite-area .game-card-wrapper {

	filter: drop-shadow(0.1rem 0.1rem);
}

@media (min-width: 601px) {

	.scene .game-card-wrapper {

		filter: drop-shadow(0.3rem 0.3rem);
	}

	.scene .opposite-area .game-card-wrapper {

		filter: drop-shadow(0.2rem 0.2rem);
	}
}

@media only screen and (max-height: 600px) {

	.game-area {

		bottom: -5vw;
	}
}

.scene .game-area-data {

  width: 33.4vw;
  height: 12vw;
  left: 3vw;
  position: absolute;
  top: calc(25vh + 73vw);
  font-size: 4vw;
  color: #d1caae;
  flex-direction: column;
  flex-wrap: wrap;
}

.scene .opposite-area .game-area-data {

  top: calc(25vh - 21.5vw);
}

.scene .game-area-data-stat {

  width: 16.7vw;
}

.scene .game-area-data-stat-icon {

	height: 1em;
	width: 1em;
	object-fit: contain;
}

.scene .game-area-data-hand, .scene .game-area-data-deck {

	padding-left: 3vw;
}

.scene .game-area-data-stat-value {

	margin-left: 0.5em;
}

.scene .turn-indicator {

  width: 33.4vw;
  height: 2vw;
  bottom: 11vw;
  left: 3vw;
  position: absolute;
  justify-content: flex-start;
  align-items: center;
  font-size: 2vw;
  color: #9c9a97;
  opacity: 1;
  transition: opacity 1s;
}

.scene .fade.turn-indicator {

	opacity: 0;
  transition: opacity 0.2s;
}

.scene .playing.turn-indicator {

  color: #edeae4;
  font-weight: bold;
}

/*
.scene .turn-indicator {

  width: 1.1vw;
  height: 1.1vw;
  top: 2.5vw;
  left: 2.5vw;
  transition: left 0.8s ease-in-out, top 0.8s ease-in-out;
  position: absolute;
  filter: drop-shadow(2px 2px 2px black);
  border-radius: 50%;
  border: silver 1px solid;
  background: -webkit-radial-gradient(65% 15%, circle, white 1px, #35ab54 3%, #155c28 60%, #35ab54 100%); 
  background: -moz-radial-gradient(65% 15%, circle, white 1px, #35ab54 3%, #155c28 60%, #35ab54 100%); 
  background: -o-radial-gradient(65% 15%, circle, white 1px, #35ab54 3%, #155c28 60%, #35ab54 100%); 
  background: radial-gradient(circle at 65% 15%, white 1px, #35ab54 3%, #155c28 60%, #35ab54 100%); 
  animation: turn-indicator-circle infinite linear 2s reverse;
}


.scene.playing .turn-indicator {

  top: calc(100% - 3.5vw);
}

@keyframes turn-indicator-circle {

	from { transform:rotate(0deg) translate(-1vw) rotate(0deg); }
	to { transform:rotate(360deg) translate(-1vw) rotate(-360deg); }
}
*/

.scene .play-card {

  width: 20vw;
  height: 6vw;
  bottom: 11.5vw;
  left: 40vw;
  position: absolute;
  justify-content: center;
  align-items: center;
  color: #d1caae;
  font-weight: bold;
  background: linear-gradient(transparent, #ffffff20, transparent);
  z-index: 12;
  opacity: 1;
  transition: opacity 0.2s;
  cursor: pointer;
}

.scene .fade.play-card {

	opacity: 0;
	pointer-events: none;
}

.scene .play-card span {

  z-index: 1;
  font-size: 2vw;
  transition: all 0.2s;
  text-shadow: 0.13vw 0.13vw #827c64;
}

.scene .play-card:hover span {

	font-size: 2.5vw;
  text-shadow: 0.16vw 0.16vw #827c64;
}

.scene .dragged-card {

	position: absolute;
	z-index: 100;
	pointer-events: none;
}

.endgame-screen {

	position: fixed;
	z-index: 1200;
	top: -25vh;
	left: 0;
	height: 150vh;
	width: 100vw;
	background: linear-gradient(to bottom, transparent, #00bdff80 50%, transparent);
	animation: endgame-enter 1s ease-in-out 1;
	justify-content: center;
	align-items: center;
}

.endgame-screen.defeat-screen {

	background: linear-gradient(to bottom, transparent, #ff001f80 50%, transparent);
}

.endgame-screen.error-screen {

	background: linear-gradient(to bottom, transparent, #51515180 50%, transparent);
}

@keyframes endgame-enter {

	0% { opacity: 0; }
	100% { opacity: 1; }
}

.endgame-screen .endgame-title {

	position: relative;
}

.endgame-screen .endgame-letter {

	opacity: 0;
	font-size: 4rem;
	animation: endgame-enter 1s ease-in-out 1;
	animation-fill-mode: forwards;
	font-style: italic;
	font-weight: bold;
	color: #0a0a21A0;
}

.endgame-screen.defeat-screen .endgame-letter {

	color: #210a0aA0;
}

.endgame-screen.error-screen .endgame-letter {

	color: #0d0d0da0;
}

.endgame-screen .endgame-runes {

	position: absolute;
	top: 6rem;
	left: calc(50% - 5rem);
	width: 10rem;
	justify-content: center;
	font-size: 2rem;
	line-height: 2rem;
	font-style: italic;
	color: #edeae4;
	text-shadow: 0.1rem 0.1rem 0px #00000080;
	opacity: 0;
	animation: endgame-enter 1s ease-in-out 1;
	animation-delay: 1.5s;
	animation-fill-mode: forwards;
}

.endgame-screen.defeat-screen .endgame-runes {

	color: #edeae4;
}

.endgame-screen.error-screen .endgame-runes {

	color: #edeae4;
}

.endgame-screen .endgame-runes .runes-icon {

	margin-left: 0.5rem;
	margin-top: 0.25rem;
	width: 1.5rem;
	height: 1.5rem;
}

.endgame-scene .scene {

	filter: grayscale(0.5) brightness(0.5);
}

.scene-wrapper {

	background: #00000000;
	transition: background 2s;
}

.scene-wrapper.endgame-scene {

	background: #00000080;
}

.sensuba-scene-background {

	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	overflow: hidden;
}

.scene .game-attack-action {

	position: absolute;
	top: 0;
	left: 0%;
	width: 80%;
	height: 70%;
	pointer-events: none;
	margin: 12vw 10%;
	opacity: 0;
	transition: opacity 0.1s;
	filter: drop-shadow(#00000060 0.15rem 0.15rem) brightness(0.8);
	flex-direction: column-reverse;
}

.scene .game-attack-action.active {

	opacity: 0.8;
}

.scene .game-attack-action img {

	object-fit: fill;
	width: 100%;
	height: 100%;
}

.scene .game-attack-action.active img {

	animation: game-attack-slide 0.3s;
}

@keyframes game-attack-slide {

	from { height: 0%; opacity: 0 }
	to { height: 100%; opacity: 1 }
}

@media (min-width: 601px) {

	.scene .game-area-data {

	    width: 26rem;
	    height: 3vh;
	    left: calc(51vw + 12vh);
	    position: absolute;
	    top: calc(73vh + 0px);
	    font-size: 2.5vh;
	    color: #edeae4;
	    flex-direction: row;
	    flex-wrap: wrap;
	}

	.scene .game-area-data-stat-icon {

		width: 3vh;
		height: 3vh;
	}

	.scene .opposite-area .game-area-data {

	    top: 10vh;
	}

	.scene .game-area-data-stat {

	    width: 5.5rem;
	}

	.scene .game-area-data-hand, .scene .game-area-data-deck {

	    padding-left: 0;
	}

  .scene .turn-indicator {

		width: calc(15vw + 15vh);
	  height: 2vh;
	  bottom: 24.5vh;
	  left: 3vh;
	  font-size: 3.5vh;
	  justify-content: center;
	}

	.scene .game-buttons {

		width: calc(15vw + 15vh);
	  justify-content: center;
	    font-size: 1.9vh;
	    left: 3vh;
	    bottom: 13.75vh;
	}

	.scene .game-attack-action {

		width: 60%;
		margin: 8.4vh 20%;
	}
}

@media only screen and (max-width: 600px) {
  
  .scene .game-area-info {

  	display: none;
  }

  .scene .game-buttons {

  	bottom: unset;
  	left: unset;
  	top: calc(25vh + 72vw);
  	right: 1vw;
  	font-size: 2vw;
  }

  .scene .game-hand {

  	width: 100vw;
  	height: 32vw;
  	top: calc(25vh + 83vw);
  	bottom: unset;
  }

  .scene .game-court {

  	width: 50vw;
  	height: 32vw;
  	top: calc(25vh + 35vw);
  	bottom: unset;
  }

  .scene .opposite-area .game-hand {

  		transform: none;
  		left: unset;
  		right: 0;
  		justify-content: flex-end;
  		flex-direction: row-reverse;
  		height: 20vw;
  		align-content: center;
  		margin-top: 6.25vw;
  		padding-left: 2vw;
  }

  .scene .opposite-area .game-court {

  	width: 50vw;
  	height: 32vw;
  	top: calc(25vh + 0vw);
  	bottom: unset;
  }

  .scene .turn-indicator {

  	bottom: unset;
  	top: calc(25vh + 77vw);
  	font-size: 4vw;
  	display: none;
  }

	.scene .dragged-card {

		font-size: 2.5vw;
		transform: translate(-10vw, -7.5vw);
	}
}