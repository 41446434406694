.sensuba-card-damage .game-unit, .sensuba-card-damage.game-hero {

	animation: card-damage 0.2s 1;
}

.sensuba-card-damage .game-digitanim {

	display: block;
	animation: card-damage-digit 0.6s 1;
}

@keyframes card-damage {
  0% {filter: contrast(1)}
  50% {filter: contrast(3)}
  100% {filter: contrast(1)}
}

@keyframes card-damage-digit {
  0% { font-size: 6em; line-height: 2em; opacity: 1; color: white;}
  50% { font-size: 8em; line-height: 1.5em; opacity: 1; color: white;}
  100% { font-size: 10em; line-height: 1.2em; opacity: 0; color: black;}
}