.game-hand {

	width: 100%;
	justify-content: space-around;
	height: 16vw;
	box-sizing: border-box;
	pointer-events: none;
	flex-wrap: wrap;
	justify-content: center;
	align-content: flex-start;
}

.game-hand-card {

	top: 0;
    z-index: 1;
    width: 8vw;
    height: 6vw;
	pointer-events: all;
	transition: 0.4s left, 0.4s top, 0.4s transform, 0.4s opacity, 0.2s filter;
	margin: 1vw;
	font-size: 1.2vw;
	position: absolute;
}

.game-hand-card-unplayable {

	filter: grayscale(0.5) brightness(0.6);
}

.game-hand-card .game-card-wrapper {

	position: absolute;
	font-size: 1.2vw;
	border-radius: 1.45vw;
	border-bottom-left-radius: 60% 12%;
	border-bottom-right-radius: 60% 12%;
}

.self-area .game-hand-card {

    cursor: pointer;
}
/*
.self-area .game-hand-card:hover .game-card-wrapper {

	top: 1vw;
}

.opposite-area .game-hand-card .game-card-wrapper {

    top: 1.5vw;
}

.opposite-area .game-hand-card:hover .game-card-wrapper {

	top: 1vw;
}
*/
.self-area .game-hand-card:hover {

    z-index: 2;
}

.card-focus {

	box-shadow: 0 0 0.2em 0.2em red;
}

@media (min-width: 601px) {

	.scene .game-hand {

		width: calc(70vw - 20vh);
		right: calc(15vw - 12vh);
		height: 15vh;
		flex-wrap: nowrap;
	    justify-content: flex-end;
	}

	.game-hand-card {

	    height: 12vh;
		margin: 1vh;
		top: 0em;
		animation: game-hand-card-appear 0.3s ease-out;
	}

	@keyframes game-hand-card-appear {
		from { top: 6.5em }
		to { top: 0em }
	}

	.game-hand-card .game-card-wrapper {

	    font-size: 2.4vh;
	    border-radius: 2.9vh;
	}

	.scene .opposite-area .game-hand {

		width: calc(42vw - 25vh);
		position: fixed;
	    top: 0;
		height: 15vh;
	    left: calc(4vw + 8vh);
	    transform: rotateZ(180deg);
	}

	.opposite-area .game-hand-card {

		width: 10%;
		animation: game-hand-card-appear-opposite 0.3s ease-out;
	}

	  @keyframes game-hand-card-appear-opposite {
		from { transform: translateX(10vh); opacity: 0 }
		to { transform: translateX(0vh); opacity: 1 }
	  }

	.opposite-area .game-hand-card .game-card-wrapper {

	    font-size: 1.7vh;
	    border-radius: 2.175vh;
	    transform: rotateZ(180deg);
	}

	.opposite-area .game-hand {

		width: 10vw;
	}
}

@media only screen and (max-width: 600px) {
  
  .game-hand-card {

  	width: 18% !important;
  	height: 13.5vw;
  	font-size: 2em;
  	margin: unset;
  	padding: 1%;
  	top: 0;
	animation: game-hand-card-appear 0.3s ease-out;
  }

  @keyframes game-hand-card-appear {
	from { transform: translateX(10vw); opacity: 0 }
	to { transform: translateX(0vw); opacity: 1 }
  }
  
  .opposite-area .game-hand {

	overflow: visible;
  }
  
  .opposite-area .game-hand-card {

	animation: game-hand-card-appear-opposite 0.3s ease-out;
  }

  @keyframes game-hand-card-appear-opposite {
	from { transform: translateX(-10vw); opacity: 0 }
	to { transform: translateX(0vw); opacity: 1 }
  }

  .game-hand {

  	pointer-events: all;
    padding: 1vw;
  }

  .game-hand-card .game-card-wrapper {

  	/*top: 10vw;*/
  	font-size: 2vw;
  }

	.opposite-area .game-hand {

  		transform: none;
  		left: unset;
  		right: 0;
	}

	.opposite-area .game-hand-card {

  		width: 3% !important;
	}
/*
	.self-area .game-hand-card:hover .game-card-wrapper {

		top: 3vw;
	}

	.opposite-area .game-hand-card .game-card-wrapper {

	    top: 4.5vw;
	}

	.opposite-area .game-hand-card:hover .game-card-wrapper {

		top: 3vw;
	}*/
}



.game-hand-card .game-temporary::after {

    position: absolute;
    top: 0em;
    left: 0em;
    width: 8em;
    height: 6em;
    overflow: hidden;
    margin: 0.4em;
    animation: temporary-color 7s infinite linear, temporary-border 4s infinite alternate;
}

@keyframes temporary-color {
  0% { background: rgba(175, 255, 255, 0.25); }
  20% { background: rgba(220, 255, 255, 0.4); }
  50% { background: rgba(175, 255, 255, 0.25); }
  80% { background: rgba(130, 255, 255, 0.4); }
  100% { background: rgba(175, 255, 255, 0.25); }
}

@keyframes temporary-border {
  from { box-shadow: inset 0 0 0.7em 0.35em rgba(64, 0, 128, 0.8), 0 0 0.6em 0.3em rgba(64, 0, 128, 0.8); }
  to { box-shadow: inset 0 0 1em 0.5em rgba(0, 0, 0, 1), 0 0 0.7em 0.35em rgba(0, 0, 0, 1); }
}