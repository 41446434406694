.play-page .main {

	flex-direction: row;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.play-buttons {

	justify-content: space-around;
	width: 80%;
	height: 60%;
	flex-direction: column;
}

@media only screen and (max-width: 600px) {

}