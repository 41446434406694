.story-page .main {

	justify-content: space-evenly;
	align-items: center;
	height: 100vh;
}

.story-page .worlds {

	width: 100%;
	justify-content: center;
	align-items: center;
	background: radial-gradient(circle at 50% 100%, black, transparent 20rem);
}

.story-page .world-choser {

	position: relative;
	flex-direction: column;
	justify-content: center;
	width: 100%;
	overflow-x: hidden;
    margin-top: -2rem;
    padding-top: 2rem;
}

.story-page .enter-world {

	justify-content: center;
}

.story-page .world-info {

	color: #edeae4;
	flex-direction: column;
	max-width: 80%;
}

.story-page .world-info h2 {

	justify-content: center;
	font-size: 2rem;
	height: 2.5rem;
	align-items: center;
	margin: 0.2rem;
}

.story-page .world-info p {

	font-size: 1.4rem;
	height: 4.2rem;
	align-items: center;
	margin: 0;
}

@media only screen and (max-width: 600px) {

	.story-page .world-info {

		max-width: 90%;
	}

	.story-page .world-info p {

		font-size: 1rem;
	}
}

@media only screen and (max-height: 600px) {


	.story-page .world-info h2 {

		font-size: 1.4rem;
	}

	.story-page .world-info p {

		font-size: 1rem;
	}

	.story-page .enter-world {

		margin-top: -2rem;
	}
}