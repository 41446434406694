.portals-page .main {

	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.portals-page-nav {

	height: 4rem;
	width: 100%;
	border-bottom: 0.2rem lightgray solid;
	background: #2a2011;
}

.portals-page-search {
	
	width: 100%;
	box-shadow: 0px 0.2rem 0.2rem 0px #000000A0;
	z-index: 99;
	flex-direction: column;
}

.portals-page-main-wrapper {

	height: calc(100% - 6.9rem);
	width: 100%;
	flex-direction: column;
	position: relative;
}

.portals-page-main-style {

	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	animation: style-fadein 0.3s ease-in-out;
}

.style-background {

	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
}

.style-objects {

	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	justify-content: space-evenly;
	overflow: hidden;
}

.portals-page-main-style-fadeout {

	height: 100%;
	width: 100%;
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	animation: style-fadein 0.3s ease-in-out reverse;
}

@keyframes style-fadein {
	from { opacity: 0 }
	to { opacity: 1 }
}

.portals-page-main {

	height: 100%;
	width: 100%;
	flex-direction: column;
}

.portals-page-search-main {

	width: 100%;
}

.portals-page-search-tab {
	
	height: 2.5rem;
	width: 100%;
	border-left: solid 1px #ffffff1c;
	border-bottom: solid 1px #ffffff1c;
	background: linear-gradient(60deg, #000000A0, #00000050);
	flex-direction: column;
	padding: 0.2rem 0.3rem;
	font-size: 1rem;
	overflow: hidden;
}

.portals-page-search-tab:active {

	background: linear-gradient(60deg, #00000050, #00000020)
}

.portals-page-search-tab:nth-child(1) {
	
	border-left: none;
}

.portals-page-search-tab .portals-page-search-label {

	color: #7f7a6a;
	white-space: nowrap;
}

.portals-page-search-tab .portals-page-search-value {

	color: #edeae4;
	white-space: nowrap;
	max-width: 100%;
}

.portals-page-search-main {

	width: 100%;
}

.portals-page-explore-tab {

	width: calc(100% - 0.6rem);
	height: 1.2rem;
	border-bottom: solid 1px #ffffff1c;
	background: #000000A0;
	padding: 0.1rem 0.3rem;
	font-size: 1rem;
	overflow: hidden;
	justify-content: space-between;
	transition: background 0.2s;
}

.portals-page-explore-tab.explore-ready {

	cursor: pointer;
}

.portals-page-explore-tab.explore-ready:hover {

	background: #202020A0;
}

.portals-page-explore-tab .portals-page-explore-portal {

	color: #7f7a6a;
	white-space: nowrap;
	transition: color 0.2s;
}

.portals-page-explore-tab.explore-ready:hover .portals-page-explore-portal {

	color: #a19e93;
}

.portals-page-explore-tab .portals-page-explore-timer {

	color: #edeae4;
	white-space: nowrap;
	max-width: 100%;
	transition: color 0.2s;
}

.portals-page-explore-tab.explore-ready .portals-page-explore-timer {

	color: #f1a635;
}

.portals-page-explore-tab.explore-ready:hover .portals-page-explore-timer {

	color: #f8ba5e;
}


.portals-page .portals-page-main {

	max-width: 100vw;
}

.portal-list {

	width: 100%;
  overflow: auto;
  flex-wrap: nowrap;
  position: relative;
  justify-content: left;
  padding: 0.5em 0.5rem;
  font-size: 2vh;
  height: 9.5em;
}

.portal-list .sensuba-portal {

	margin: 0 0.5em;
	cursor: pointer;
}

.portal-list .selected-portal .sensuba-portal {

	cursor: default;
}

.portal-list .listed-portal.listed-hero {

	height: 100%;
	justify-content: center;
	align-items: center;
	padding: 0 0.5em;
	transition: filter 0.2s;
	cursor: pointer;
}

.portal-list .listed-portal.listed-hero.selected-hero {

	cursor: default;
}

.portal-list .listed-portal.listed-hero:hover, .portal-list .listed-portal.listed-hero.selected-hero {

	filter: brightness(0.5);
}

.portal-list .listed-portal .circle {

    position: absolute;
    height: 130%;
    width: 130%;
    margin-top: -10%;
    margin-left: -10%;
    filter: url(#wave);
    opacity: 0.6;
    z-index: 2;
}

.portals-page-search-main-label:hover {

	color: #96928B;
}

.portals-page-search-main-label:active {

	color: #C3C0B0;
}

.portals-page-search-main-label {

	color: #86837E;
	white-space: nowrap;
	font-size: 1.6rem;
	line-height: 2.5rem;
	justify-content: center;
	height: 100%;
	transition: color 0.2s;
	width: 100%;
}

.portal-focus {

	align-items: center;
	height: calc(100% - 10.5rem);
	flex-direction: column;
	position: relative;
}

.portal-focus .focused-portal {

	font-size: 6vw;
	margin-top: 3rem;
}

.hero-mode .portal-focus .focused-portal {

	margin-top: calc(3rem + 1em);
	margin-bottom: 2em;
}

.portal-focus .sensuba-hero {

	cursor: pointer;
}

.portal-focus .cardbox-ability {

	cursor: pointer;
}

.portal-focus .portal-cards {

	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 30vw;
	flex-direction: column;
	align-items: center;
	font-size: 2.4vw;
	padding-top: 14vw;
}

.portal-focus .portal-cards + .portal-cards {

	left: unset;
	right: 0;
}

.hero-mode .portal-focus .portal-cards {

	padding-top: 10vw;
	height: 54vw;
	width: 26vw;
	justify-content: space-evenly;
}

.hero-mode .portal-focus .portal-cards + .portal-cards {

}

.portal-focus .portal-cards .portal-card-wrapper {

	margin-top: -4.5vw;
	cursor: pointer;
}

.portal-card-wrapper {

	transition: filter 0.2s;
}

.portal-card-wrapper.card-owned {

	filter: grayscale(1) brightness(0.35);
}

.portal-focus .sensuba-portal .portal-name {

	display: none;
}

.portal-focus .portal-description {

	position: absolute;
	top: calc(7rem + 54vw);
	left: 0;
	color: #dec6a2;
	font-size: 0.8rem;
	padding-left: 2rem;
	padding-right: 2rem;
	justify-content: center;
}

.portal-focus .portal-name {

	position: absolute;
	height: 2.6rem;
	align-items: center;
	top: 0;
	left: 0;
	color: #edeae4;
	font-size: calc(0.8rem + 1vw);
	line-height: 1.1rem;
	padding-left: 29vw;
	padding-right: 29vw;
	width: 42vw;
	justify-content: center;
}

.portal-focus .portal-cards-text-short {

	position: absolute;
	height: 1.5rem;
	width: 30vw;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	color: #dec6a280;
	font-size: calc(0.5rem + 1vw);
}

.hero-mode .portal-focus .portal-cards-text-short {

	margin-top: 2.5vw;
	width: 27vw;
	font-size: 1.5rem;
}

.portal-focus .portal-cards-text-short + .portal-cards-text-short {

	left: unset;
	right: 0;
}

.portal-focus .portal-cards-text-long {

	display: none;
}

.portal-focus-buttons {

	width: 100%;
	height: 4rem;
	justify-content: space-evenly;
	align-items: center;
}

.portal-focus-button {

	font-size: calc(1rem + 1vw);
	color: #f1a635;
	padding: 0.1rem 1rem;
	background: linear-gradient(to right, transparent, #23221f80, transparent);
	transition: color 0.2s;
	position: relative;
	cursor: pointer;
	z-index: 1;
}

.portal-focus-button:hover {

	color: #edeae4;
}

.portal-focus-button.locked, .portal-focus-button.locked:hover {

	color: grey;
	cursor: default;
}

.portal-focus-button::before {
	
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	pointer-events: none;
	transition: opacity 0.3s;
	background: linear-gradient(to right, transparent, #23221f 10%, #23221f 90%, transparent);
	opacity: 0;
	z-index: -1;
}

.portal-focus-button:hover::before {
	
	opacity: 1;
}

.portal-focus-button.locked:hover::before {
	
	opacity: 0;
}

.portal-focus-button .cost {

	font-size: 0.8em;
	padding-top: 0.24em;
	margin-left: 0.7em;
}

.portals-page-craftcard .cost {

	font-size: 0.8em;
	margin-left: 0.7em;
	align-items: center;
}

.portals-page-craftcard .cost .shards-icon {

	width: 1.5rem;
	height: 1.5rem;
	margin-top: unset;
}

.portal-focus-button .runes-icon, .portal-focus-button .shards-icon {

	height: 0.8em;
	width: 0.8em;
	margin-top: 0.24em;
	margin-right: 0.3em;
	filter: drop-shadow(0.1em 0.1em #00000080);
}

.explore-box h1 {

	text-align: center;
	align-self: center;
	margin-top: 0.5rem;
	margin-bottom: 1.5rem;
	font-size: 2rem;
	line-height: 2rem;
	justify-content: center;
	white-space: nowrap;
	color: #edeae4;
}

.time-icon {

	height: 1em;
	width: 1em;
	margin-top: 0em;
	margin-right: 0.3em;
	background: url('./time.png');
	background-size: 100%;
	background-repeat: no-repeat;
	filter: drop-shadow(0.1em 0.1em #00000080);
}

.lightbox-reward.lightbox-container {

	background-color: #222E;
	opacity: 1;
	transition: opacity 0.3s;
}

.lightbox-reward.lightbox-container.hidden-reward {

	opacity: 0;
	pointer-events: none;
}

.lightbox-reward.lightbox-container .lightbox-inner {

	justify-content: center;
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
}

.lightbox-reward.lightbox-container .reward-list {

	width: 80%;
	height: 80%;
	max-width: 80rem;
	justify-content: space-evenly;
	align-items: center;
	flex-direction: column;
}

.lightbox-reward.lightbox-container .reward {

	width: 8em;
	height: 6em;
}

.lightbox-reward.lightbox-container .reward.card-reward {

	cursor: pointer;
	filter: drop-shadow(0.2rem 0.2rem #000000A0);
}

.lightbox-reward.lightbox-container .reward.shards-reward {

	width: 8em;
	height: 6em;
	color: #edeae4;
	justify-content: center;
	align-items: center;
}

.lightbox-reward.lightbox-container .reward.shards-reward .shards-reward-value {

	font-size: 2.5rem;
	filter: drop-shadow(0.2rem 0.2rem #000000A0);
}

.lightbox-reward.lightbox-container .reward.shards-reward .shards-icon {

	width: 3rem;
	height: 3rem;
	margin: 0;
	margin-left: 0.3rem;
}

.lightbox-reward.lightbox-container .reward.shards-reward {

	width: 8em;
	height: 6em;
}

.portals-page .portals-page-craftcard {
	
	position: absolute;
	top: calc(50% + 10rem);
	left: calc(50% - 6.5rem - 2px);
	width: 13rem;
	height: 3rem;
	z-index: 1001;
	justify-content: center;
	border-radius: 1rem;
	overflow: hidden;
	border: 2px solid white;
	font-size: 1.4rem;
	line-height: 3rem;
	background: linear-gradient(to bottom, #6693e1, #3e6bb9);
	color: #edeae4;
	cursor: pointer;
}

.portals-page .portals-page-craftcard:active {
	
	background: linear-gradient(to bottom, #7d92b8, #475e86);
	color: #c8c5be;
}


@media (min-width: 601px) {

	.portals-page .portals-page-main {

		max-width: 80vw;
		margin: auto;
	}

	.portals-page-search {

	    box-shadow: 0px 0.2rem 0.2rem 0px #000000a0;
	    z-index: 99;
	}

	.portal-list {

		font-size: calc(0.6rem + 1vh);
	}

	.portals-page-search-tab {
		
		cursor: pointer;
	}

	.portals-page-search-tab:hover {

		background: linear-gradient(60deg, #00000050, #00000020)
	}

	.portals-page-search-tab:active {

		background: linear-gradient(60deg, #FFFFFF16, #FFFFFF06)
	}

	.portal-focus .focused-portal {

		font-size: calc(8vh - 1.2rem);
	}

	.hero-mode .portal-focus .focused-portal {

		font-size: calc(8vh - 1.2rem);
	}

	.portal-focus .portal-cards {

		position: absolute;
		top: 0;
		height: 100%;
		width: 20vh;
		left: calc(50% + 10vh + 8vw);
		flex-direction: column;
		align-items: center;
		font-size: 2vh;
		padding-top: 14vh;
	}

	.hero-mode .portal-focus .portal-cards {

		flex-direction: row;
		width: calc(40vh + 10vw);
		height: 20vh;
		top: 10vh;
		padding-top: unset;
		justify-content: center;
	}

	.hero-mode .portal-focus .portal-cards .cardbox-ability {

		margin: 0 1em;
	}

	.portal-focus .portal-cards + .portal-cards {

		left: calc(50% + 26vh + 10vw);
		right: unset;
	}

	.hero-mode .portal-focus .portal-cards + .portal-cards {

		left: calc(50% + 10vh + 8vw);
		right: unset;
		top: 30vh;
	}

	.hero-mode .portal-focus .portal-cards-text-short {

		display: inherit;
		width: calc(40vh + 10vw);
		left: calc(50% + 10vh + 8vw);
	    height: 20vh;
	    top: 0vh;
	    margin-top: 0;
		justify-content: center;
		font-size: 2rem;
	}

	.hero-mode .portal-focus .portal-cards-text-short + .portal-cards-text-short {

	    top: 20vh;
	}

	.portal-focus .portal-cards .portal-card-wrapper {

		margin-top: calc(0.7rem - 6.5vh);
	}

	.portal-focus .portal-name {

		position: absolute;
		top: 2.5rem;
		height: 5rem;
		align-items: center;
		left: calc(50% - 28vh - 27vw);
		width: calc(8vh + 22vw);
		color: #edeae4;
		font-size: 1.5rem;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.portal-focus .portal-description {

		position: absolute;
		top: 7.5rem;
		left: calc(50% - 28vh - 27vw);
		width: calc(8vh + 22vw);
		font-size: 1.2rem;
		padding-left: 2rem;
		padding-right: 2rem;
	}

	.portal-focus .portal-cards-text-short {

		display: none;
	}

	.portal-focus .portal-cards-text-long {

		display: flex;
		position: absolute;
		height: 1.5rem;
		width: calc(37vh + 2vw);
		left: calc(50% + 10vh + 8vw);
		align-items: center;
		justify-content: center;
		top: 3vh;
		color: #dec6a280;
		font-size: 1rem;
	}

	.portal-focus-buttons {

		position: absolute;
		top: 16.5rem;
		left: calc(50% - 28vh - 27vw);
		width: calc(4rem + 8vh + 22vw);
		flex-direction: column;
	}

	.portal-focus-button {

		margin: 0.5rem;
	}

	.portal-focus-button .runes-icon, .portal-focus-button .shards-icon {

		height: 1.2em;
		width: 1.2em;
		margin-top: 0em;
		margin-right: 0.3em;
		filter: drop-shadow(0.1em 0.1em #00000080);
	}

	.time-icon {

		height: 1.2em;
		width: 1.2em;
		margin-top: 0em;
		margin-right: 0.3em;
		background: url('./time.png');
		background-size: 100%;
		background-repeat: no-repeat;
		filter: drop-shadow(0.1em 0.1em #00000080);
	}

	.lightbox-reward.lightbox-container .reward-list {

		flex-direction: row;
	}

	.lightbox-reward.lightbox-container .reward.shards-reward .shards-reward-value {

		font-size: 3rem;
	}

	.lightbox-reward.lightbox-container .reward.shards-reward .shards-icon {

		width: 4rem;
		height: 4rem;
		margin-left: 0.5rem;
	}

	.portals-page-search {
	
		flex-direction: row;
		border-bottom: solid 1px #ffffff1c;
	}

	.portals-page-search-main {

		width: calc(100% - 20vw - 15rem);
		border-bottom: none;
	}

	.portals-page-search-tab:nth-child(2) {
	    
	    border-right: solid 1px #ffffff1c;
	}

	.portals-page-explore-tab {

		width: calc(20vw + 14.6rem);
		height: calc(100% - 0.4rem);
		border-bottom: none;
		padding: 0.2rem 0.6rem;
		font-size: 1.1rem;
		line-height: 1.1rem;
		overflow: hidden;
		flex-direction: column;
	}

	.explore-box h1 {

		font-size: 2.5rem;
		line-height: 2.5rem;
		margin-left: 1rem;
		margin-right: 1rem;
	}

	.portals-page .portals-page-craftcard {

	    top: calc(50% + 14rem);
	    left: calc(50% - 7.5rem - 2px);
	    width: 15rem;
	    font-size: 1.5rem;
	}
}





.style-objects.stars {

	min-width: 25em;
}

@media min-width(601px) { .style-object.stars { min-width: 100em } }

.style-objects.stars .style-object {

	position: relative;
	top: 0;
	left: 0;
	animation: style-object-shine 15s ease-in-out alternate infinite;

	width: 1em;
	height: 2px;
	border-radius: 50%;
	filter: drop-shadow(0 0 0.5rem #80FFFF80);
}

.style-objects.stars .style-object::before, .style-objects.stars .style-object::after {

	position: absolute;
	content: '';
	top: 0;
	left: calc(1em / -2);
	width: 1em;
	height: 100%;
	background: linear-gradient(45deg, transparent, #80FFFF, transparent);
	border-radius: inherit;
}

.style-objects.stars .style-object::before {

	transform: rotate(0deg);
}

.style-objects.stars .style-object::after {

	transform: rotate(90deg);
}

.style-objects.stars .style-object:nth-child(1) { top: 15%; animation-delay: -1s; }
.style-objects.stars .style-object:nth-child(2) { top: 85%; animation-delay: -15s; }
.style-objects.stars .style-object:nth-child(3) { top: 55%; animation-delay: -10s; }
.style-objects.stars .style-object:nth-child(4) { top: 25%; animation-delay: -7s; }
.style-objects.stars .style-object:nth-child(5) { top: 95%; animation-delay: -17s; }
.style-objects.stars .style-object:nth-child(6) { top: 65%; animation-delay: -1s; }
.style-objects.stars .style-object:nth-child(7) { top: 25%; animation-delay: -19s; }
.style-objects.stars .style-object:nth-child(8) { top: 75%; animation-delay: -12s; }
.style-objects.stars .style-object:nth-child(9) { top: 5%; animation-delay: -6s; }
.style-objects.stars .style-object:nth-child(10) { top: 35%; animation-delay: -14s; }
.style-objects.stars .style-object:nth-child(11) { top: 15%; animation-delay: -18s; }
.style-objects.stars .style-object:nth-child(12) { top: 85%; animation-delay: -4s; }
.style-objects.stars .style-object:nth-child(13) { top: 55%; animation-delay: -9s; }
.style-objects.stars .style-object:nth-child(14) { top: 25%; animation-delay: -16s; }
.style-objects.stars .style-object:nth-child(15) { top: 95%; animation-delay: -11s; }
.style-objects.stars .style-object:nth-child(16) { top: 65%; animation-delay: -0s; }
.style-objects.stars .style-object:nth-child(17) { top: 25%; animation-delay: -8s; }
.style-objects.stars .style-object:nth-child(18) { top: 75%; animation-delay: -5s; }
.style-objects.stars .style-object:nth-child(19) { top: 5%; animation-delay: -20s; }
.style-objects.stars .style-object:nth-child(20) { top: 35%; animation-delay: -13s; }

@keyframes style-object-shine {
	0% { opacity: 0 }
	35% { opacity: 0 }
	100% { opacity: 1 }
}





.style-objects.snow .style-object {

	position: relative;
	top: 0;
	left: 0;

	width: 10px;
	height: 10px;
	border-radius: 50%;
	background: white;
	opacity: 0.15;
    /*box-shadow: 0 0 15px 10px hsla(0,0%,50%,.5);*/
	/*filter: drop-shadow(0 0 0.5rem #80FFFF80);*/
	animation: style-object-fall 40s linear infinite;
}

.style-objects.snow .style-object:nth-child(20n + 1) { width: 10px; height: 10px; animation-delay: -2s; animation-duration: 36s; }
.style-objects.snow .style-object:nth-child(20n + 2) { width: 12px; height: 12px; animation-delay: -30s; animation-duration: 32s; }
.style-objects.snow .style-object:nth-child(20n + 3) { width: 9px; height: 9px; animation-delay: -20s; animation-duration: 40s; }
.style-objects.snow .style-object:nth-child(20n + 4) { width: 11px; height: 11px; animation-delay: -14s; animation-duration: 33s; }
.style-objects.snow .style-object:nth-child(20n + 5) { width: 8px; height: 8px; animation-delay: -34s; animation-duration: 28s; }
.style-objects.snow .style-object:nth-child(20n + 6) { width: 10px; height: 10px; animation-delay: -2s; animation-duration: 37s; }
.style-objects.snow .style-object:nth-child(20n + 7) { width: 7px; height: 7px; animation-delay: -38s; animation-duration: 31s; }
.style-objects.snow .style-object:nth-child(20n + 8) { width: 9px; height: 9px; animation-delay: -24s; animation-duration: 35s; }
.style-objects.snow .style-object:nth-child(20n + 9) { width: 12px; height: 12px; animation-delay: -12s; animation-duration: 32s; }
.style-objects.snow .style-object:nth-child(20n + 10) { width: 11px; height: 11px; animation-delay: -28s; animation-duration: 39s; }
.style-objects.snow .style-object:nth-child(20n + 11) { width: 8px; height: 8px; animation-delay: -36s; animation-duration: 30s; }
.style-objects.snow .style-object:nth-child(20n + 12) { width: 13px; height: 13px; animation-delay: -8s; animation-duration: 34s; }
.style-objects.snow .style-object:nth-child(20n + 13) { width: 9px; height: 9px; animation-delay: -18s; animation-duration: 29s; }
.style-objects.snow .style-object:nth-child(20n + 14) { width: 12px; height: 12px; animation-delay: -32s; animation-duration: 39s; }
.style-objects.snow .style-object:nth-child(20n + 15) { width: 10px; height: 10px; animation-delay: -22s; animation-duration: 33s; }
.style-objects.snow .style-object:nth-child(20n + 16) { width: 7px; height: 7px; animation-delay: -0s; animation-duration: 36s; }
.style-objects.snow .style-object:nth-child(20n + 17) { width: 8px; height: 8px; animation-delay: -16s; animation-duration: 32s; }
.style-objects.snow .style-object:nth-child(20n + 18) { width: 11px; height: 11px; animation-delay: -4s; animation-duration: 40s; }
.style-objects.snow .style-object:nth-child(20n + 19) { width: 9px; height: 9px; animation-delay: -40s; animation-duration: 28s; }
.style-objects.snow .style-object:nth-child(20n + 20) { width: 13px; height: 13px; animation-delay: -26s; animation-duration: 37s; }

@keyframes style-object-fall {
	0% { transform: scale(0) }
    2% { transform: scale(1); top: -50px; } 
    100% { transform: scale(0.3); top: 100%; }
}





.style-objects.glow .style-object {

	position: relative;
	top: 10em;
	left: 0;

	width: 2px;
	height: 2px;
	border-radius: 50%;
	background: #FFFFFF80;
    box-shadow: 0 0 15px 3px hsla(0,0%,50%,.5);
	/*filter: drop-shadow(0 0 0.5rem #80FFFF40);*/
	animation: style-object-shine 10s ease-in-out alternate infinite, style-object-rise 20s linear infinite;
}

.style-objects.glow .style-object:nth-child(20n + 1) { top: 10%; animation-delay: -2s; }
.style-objects.glow .style-object:nth-child(20n + 2) { top: 105%; animation-delay: -18s; }
.style-objects.glow .style-object:nth-child(20n + 3) { top: 25%; animation-delay: -12s; }
.style-objects.glow .style-object:nth-child(20n + 4) { top: 60%; animation-delay: -5s; }
.style-objects.glow .style-object:nth-child(20n + 5) { top: 5%; animation-delay: -0s; }
.style-objects.glow .style-object:nth-child(20n + 6) { top: 80%; animation-delay: -15s; }
.style-objects.glow .style-object:nth-child(20n + 7) { top: 30%; animation-delay: -8s; }
.style-objects.glow .style-object:nth-child(20n + 8) { top: 95%; animation-delay: -13s; }
.style-objects.glow .style-object:nth-child(20n + 9) { top: 50%; animation-delay: -4s; }
.style-objects.glow .style-object:nth-child(20n + 10) { top: 15%; animation-delay: -10s; }
.style-objects.glow .style-object:nth-child(20n + 11) { top: 70%; animation-delay: -19s; }
.style-objects.glow .style-object:nth-child(20n + 12) { top: 20%; animation-delay: -9s; }
.style-objects.glow .style-object:nth-child(20n + 13) { top: 55%; animation-delay: -11s; }
.style-objects.glow .style-object:nth-child(20n + 14) { top: 100%; animation-delay: -1s; }
.style-objects.glow .style-object:nth-child(20n + 15) { top: 45%; animation-delay: -16s; }
.style-objects.glow .style-object:nth-child(20n + 16) { top: 75%; animation-delay: -3s; }
.style-objects.glow .style-object:nth-child(20n + 17) { top: 30%; animation-delay: -17s; }
.style-objects.glow .style-object:nth-child(20n + 18) { top: 80%; animation-delay: -6s; }
.style-objects.glow .style-object:nth-child(20n + 19) { top: 40%; animation-delay: -17s; }
.style-objects.glow .style-object:nth-child(20n + 20) { top: 65%; animation-delay: -11s; }

@keyframes style-object-rise {
	0% { transform: translateY(0); } 
    100% { transform: translateY(-5em); }
}





.style-objects.energy .style-object {

	position: absolute;
	top: 0;
	left: 0;

	width: 100px;
	height: 100px;
	border-radius: 50%;
	background: #FFFFFF80;
	opacity: 0.05;
	/*filter: drop-shadow(0 0 0.5rem #80FFFF40);*/
	animation: style-object-float 40s linear infinite;
}

.style-objects.energy .style-object:nth-child(15n + 1) { width: 100px; height: 100px; animation-delay: -2s; animation-duration: 36s; }
.style-objects.energy .style-object:nth-child(15n + 2) { width: 120px; height: 120px; animation-delay: -30s; animation-duration: 32s; left: 6.6%; }
.style-objects.energy .style-object:nth-child(15n + 3) { width: 90px; height: 90px; animation-delay: -20s; animation-duration: 40s; left: 13.3%; }
.style-objects.energy .style-object:nth-child(15n + 4) { width: 110px; height: 110px; animation-delay: -14s; animation-duration: 33s; left: 20%; }
.style-objects.energy .style-object:nth-child(15n + 5) { width: 80px; height: 80px; animation-delay: -34s; animation-duration: 28s; left: 26.6%; }
.style-objects.energy .style-object:nth-child(15n + 6) { width: 100px; height: 100px; animation-delay: -2s; animation-duration: 37s; left: 33.3%; }
.style-objects.energy .style-object:nth-child(15n + 7) { width: 70px; height: 70px; animation-delay: -38s; animation-duration: 31s; left: 40%; }
.style-objects.energy .style-object:nth-child(15n + 8) { width: 90px; height: 90px; animation-delay: -24s; animation-duration: 35s; left: 46.6%; }
.style-objects.energy .style-object:nth-child(15n + 9) { width: 120px; height: 120px; animation-delay: -12s; animation-duration: 32s; left: 53.3%; }
.style-objects.energy .style-object:nth-child(15n + 10) { width: 110px; height: 110px; animation-delay: -28s; animation-duration: 39s; left: 60%; }
.style-objects.energy .style-object:nth-child(15n + 11) { width: 80px; height: 80px; animation-delay: -36s; animation-duration: 30s; left: 66.6%; }
.style-objects.energy .style-object:nth-child(15n + 12) { width: 130px; height: 130px; animation-delay: -8s; animation-duration: 34s; left: 73.3%; }
.style-objects.energy .style-object:nth-child(15n + 13) { width: 90px; height: 90px; animation-delay: -18s; animation-duration: 29s; left: 80%; }
.style-objects.energy .style-object:nth-child(15n + 14) { width: 120px; height: 120px; animation-delay: -32s; animation-duration: 39s; left: 86.6%; }
.style-objects.energy .style-object:nth-child(15n + 15) { width: 100px; height: 100px; animation-delay: -22s; animation-duration: 33s; left: 93.3%; }
.style-objects.energy .style-object:nth-child(16) { left: 50%; }
.style-objects.energy .style-object:nth-child(17) { left: 56.6%; }
.style-objects.energy .style-object:nth-child(18) { left: 63.3%; }
.style-objects.energy .style-object:nth-child(19) { left: 70%; }
.style-objects.energy .style-object:nth-child(20) { left: 76.6%; }
.style-objects.energy .style-object:nth-child(21) { left: 83.3%; }
.style-objects.energy .style-object:nth-child(22) { left: 90%; }
.style-objects.energy .style-object:nth-child(23) { left: 96.6%; }
.style-objects.energy .style-object:nth-child(24) { left: 3.3%; }
.style-objects.energy .style-object:nth-child(25) { left: 10%; }
.style-objects.energy .style-object:nth-child(26) { left: 16.6%; }
.style-objects.energy .style-object:nth-child(27) { left: 23.3%; }
.style-objects.energy .style-object:nth-child(28) { left: 30%; }
.style-objects.energy .style-object:nth-child(29) { left: 36.6%; }
.style-objects.energy .style-object:nth-child(30) { left: 43.3%; }

@keyframes style-object-float {
	0% { transform: scale(0); top: 100%; }
    2% { transform: scale(1); top: 100%; } 
    100% { transform: scale(0.3); top: -100px; }
}