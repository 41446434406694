.menu-button-container {

	justify-content: center;
	margin: 1rem;
}

.menu-button-wrapper {

}

.menu-button {

	font-size: 2rem;
	min-width: 8rem;
	padding: 0.5rem;
	line-height: 2.5rem;
	white-space: nowrap;
	justify-content: center;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	background: transparent;
	color: #d8ac6a;
	position: relative;
}

.menu-button::after {

	position: absolute;
	bottom: 0;
	left: 0;
	content: "";
	height: 2px;
	width: 100%;
	transition: all 0.2s ease-in-out;
	background: linear-gradient(to right, transparent, #d8ac6a, transparent);
}

.menu-button:hover {

	color: #EDEAE4;
}

.menu-button:hover::after {

	position: absolute;
	bottom: 0;
	left: calc(50% - 9rem);
	content: "";
	height: 2px;
	width: 18rem;
	background: linear-gradient(to right, transparent, white, transparent);
}