.sensuba-portal {

	width: 6em;
	height: 9em;
	position: relative;
  	filter: drop-shadow(#00000060 0.15rem 0.15rem);
}

.portal-list .sensuba-portal:hover .portal-dark-filter, .selected-portal .sensuba-portal .portal-dark-filter {

	opacity: 0.5;
	transition: all 0.2s;
}

.portal-frame {

	position: absolute;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	z-index: 1;
}

.inside-portal-frame {

	position: absolute;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	overflow: hidden;
	border-radius: 0.5em;
}

.inside-portal-frame .portal-fog {

	opacity: 0.6;
}

.sensuba-portal .portal-name {

	position: absolute;
	bottom: 0%;
	left: 0;
	width: 90%;
    padding: 0 5%;
	height: 40%;
	background: linear-gradient(to bottom, transparent, #00000080 20%, #00000080);
	font-size: 1em;
	color: #edeae4;
	line-height: 0.8em;
	justify-content: center;
	align-items: center;
}

svg {

   -webkit-transform: translateZ(0);
   -moz-transform: translateZ(0);
   -ms-transform: translateZ(0);
   -o-transform: translateZ(0);
   transform: translateZ(0);
}